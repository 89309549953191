import React, {useState} from "react";
import {Form, Input, Label, Row, Col, ModalFooter} from "reactstrap";
import {toast} from "react-toastify";
import OutboxIcon from "@mui/icons-material/Outbox";
import moment from "moment";
import {Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import useKommo from "../../Hooks/useKommo";

const request = new Request();

export default function KommoForm({prospect, onClose, reload}) {
  const userId = localStorage.getItem("user_id");
  const {handleKommo} = useKommo();
  const [isSubmit, setIsSubmit] = useState(false);
  const [formData, setFormData] = useState({
    subject: "",
    details: "",
  });

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);

    const data = prospect;

    const response = await handleKommo(data);

    if (response) {
      const fechaHoy = moment().format("YYYY-MM-DD");
      const registerDate = new Date();

      const tracingData = {
        id_prospecto: prospect.pkclient,
        fechaVencimiento: moment(fechaHoy).add(45, "days").format("YYYY-MM-DD"),
        id_usuario: userId || 0,
        asunto: "Se envió a Kommo",
        detalles: formData.details || ".",
        rEvento: 0,
        fEvento: moment(registerDate).format("YYYY-MM-DD"),
        hEvento: moment(registerDate).format("HH:mm:ss"),
        register_date: registerDate,
      };

      try {
        const tracingResponse = await request.post(
          "/prospectos/prospecto/seguimiento/create",
          tracingData
        );

        if (tracingResponse && !tracingResponse.error) {
          toast.success(`Se creó un seguimiento a ${prospect.client}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });

          const statusData = {
            id_prospecto: prospect.pkclient,
            nombre_prospecto: prospect.client,
            email_prospecto: prospect.emailclient,
            funnelId: 7,
            reason: "",
            id_usuario: userId || 0,
            id_desarrollo: prospect.desarrolloID,
            actividad: JSON.stringify({
              value:
                "Se realizo un cambio de embudo de <strong>" +
                prospect.funnelName +
                "</strong> a <strong>" +
                "No contactado" +
                "</strong>.",
              details: {
                from: prospect.funnelName,
                to: "No contactado",
              },
            }),
          };

          const statusResponse = await await request.post(
            "/prospectos/prospecto/status",
            statusData
          );

          if (statusResponse && !statusResponse.error) {
            toast.success("Se cambio la etapa a No contactado", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
            });
          } else {
            toast.error("Hubo un problema al cambiar el embudo", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
            });
          }
        } else {
          toast.error("Hubo un problema al crear el seguimiento", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.error("Error al crear seguimiento", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } else {
      toast.warning("Ya había sido enviado a Kommo", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
    setIsSubmit(false);
    reload();
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col xl="12" className="text-center">
          <OutboxIcon sx={{fontSize: 80}} color="primary" />
          <p className="fs-5">Se enviará a Kommo a: {prospect.client}</p>
        </Col>
        <Col xl="12">
          <Label className="col-form-label text-start">
            Si desea agregar algún detalle, escríbalo aquí:
          </Label>
          <Input
            type="textarea"
            name="details"
            value={formData.details}
            onChange={handleInputChange}
            rows="4"
          />
        </Col>
      </Row>
      <ModalFooter>
        <Btn attrBtn={{color: "secondary", onClick: onClose}}>Cancelar</Btn>
        <Btn
          attrBtn={{
            type: "submit",
            color: "primary",
            disabled: isSubmit,
          }}
        >
          {isSubmit ? "Enviando..." : "Enviar"}
        </Btn>
      </ModalFooter>
    </Form>
  );
}
