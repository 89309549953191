import Home from "../pages/home/page";
import GlobalSearch from "../pages/global-search/page";
import Error404 from "../Components/Pages/ErrorPages/ErrorPage404";
import Prospects from "../pages/prospects/page";
import Brokers from "../pages/brokers/page";
import DisponibilidadPage from "../Components/Disponibilidad/DisponibilidadPage";
import RecordatoriosPage from "../Components/Recordatorios/RecordatoriosPage";
import DesarrollosPage from "../Components/Desarrollos/DesarrollosPage";
import MotivosPage from "../Components/Motivos/MotivosPage";
import CanalesPage from "../Components/Canales/CanalesPage";
import EmbudosPage from "../Components/Embudos/EmbudosPage";
import EtapasPage from "../Components/Etapas/EtapasPage";
import RecuperacionPage from "../Components/Recuperacion/RecuperacionPage";
import Zapier from "../Components/Zapier/Zapier";
import Users from "../Components/Usuarios/Users";
import Reports from "../pages/reports/page";

export const routes = [
  {
    path: `/dashboard`,
    Component: <Home />,
  },
  {
    path: `/buscador`,
    Component: <GlobalSearch />,
  },
  {
    path: `/usuarios`,
    Component: <Users />,
  },
  {
    path: `/prospectos`,
    Component: <Prospects />,
  },
  {
    path: `/brokers`,
    Component: <Brokers />,
  },
  {
    path: `/recordatorios`,
    Component: <RecordatoriosPage />,
  },
  {
    path: `/desarrollos`,
    Component: <DesarrollosPage />,
  },
  {
    path: `/desarrollos/disponibilidad`,
    Component: <DisponibilidadPage />,
  },
  {
    path: `/motivos`,
    Component: <MotivosPage />,
  },
  {
    path: `/canales`,
    Component: <CanalesPage />,
  },
  {
    path: `/recuperacion`,
    Component: <RecuperacionPage />,
  },
  {
    path: `/embudos`,
    Component: <EmbudosPage />,
  },
  {
    path: `/etapas`,
    Component: <EtapasPage />,
  },
  {
    path: `/reportes`,
    Component: <Reports />,
  },
  {
    path: `/zapier`,
    Component: <Zapier />,
  },
  {
    path: `*`,
    Component: <Error404 />,
  },
];
