import React, {useState, useEffect} from "react";
import {Col} from "react-bootstrap";
import {Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn} from "../../../../AbstractElements";
import Request from "../../../../api/httpClient";
import Select from "react-select";
import {toast} from "react-toastify";

const request = new Request();

const BrokerEditForm = ({toggle, reload, prospecto, fuentes}) => {
  const userId = localStorage.getItem("user_id") || 0;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [nombre, setNombre] = useState(prospecto.client);
  const [email, setEmail] = useState(prospecto.emailclient);
  const [telefono, setTelefono] = useState(prospecto.phoneclient);
  const [ciudad, setCiudad] = useState(prospecto.city);
  const broker = prospecto.broker;
  const [isLoading, setIsLoading] = useState(false);
  const [internalAdvisors, setInternalAdvisors] = useState([]);
  const [selectedInternalAdvisor, setSelectedInternalAdvisor] = useState(0);
  const userRole = localStorage.getItem("Role");

  const [fuente, setFuente] = useState(
    fuentes.find((item) => item.value === prospecto.origin)
  );

  const fetchInternalAdvisors = async () => {
    setInternalAdvisors([]);
    setIsLoading(true);

    const response = await request.get("/usuarios/internal-advisors");

    if (response && !response.error) {
      if (response && !response.empty) {
        setInternalAdvisors(response);
        setSelectedInternalAdvisor(
          response.find((a) => a.id === prospecto.pkuser)
        );
      } else {
        setInternalAdvisors([]);
        setIsLoading(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }

    setIsLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitDisabled(true);

    // const advisor = internalAdvisors.find(
    //   (a) => a.value === selectedInternalAdvisor
    // );

    const editionDate = new Date();

    let data = {
      id_prospecto: prospecto.pkclient,
      cliente: nombre,
      email: email,
      telefono: telefono,
      ciudad: ciudad,
      budget: null,
      comentarios: broker === false ? prospecto.notes : null,
      interes: broker === false ? prospecto.interesting : null,
      origen: fuente.value,
      origen_otro: null,
      broker: broker,
      calificacion: broker === false ? prospecto.calificacion : 3,
      id_desarrollo: null,
      id_embudo: broker === false ? prospecto.funnelId : 9,
      posponer: null,
      advisorId: selectedInternalAdvisor ? selectedInternalAdvisor.id : 0,
      currentPhone: prospecto.phoneclient,
      currentEmail: prospecto.emailclient,
      edit_date: editionDate,
      editor_id: userId,
    };

    const response = await request.post("/prospectos/prospecto/update", data);

    if (response && !response.error) {
      toggle();
      setIsSubmitDisabled(false);
      reload(true, "Broker editado con éxito ");
    } else {
      setIsSubmitDisabled(false);
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    fetchInternalAdvisors();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col sm="12" md="12">
          <div className="project-box" style={{paddingBottom: "5px"}}>
            <div>
              <div>
                <FormGroup className="row">
                  <Col xl="6" sm="12">
                    <Label className="col-form-label text-start">Nombre:</Label>
                    <Input
                      value={nombre}
                      id="nombre"
                      type="text"
                      onChange={(e) => {
                        setNombre(e.target.value);
                      }}
                      required
                    />
                  </Col>
                  <Col xl="6" sm="12">
                    <Label className="col-form-label text-start">Email:</Label>
                    <Input
                      value={email}
                      id="email"
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Col xl="6" sm="12">
                    <Label className="col-form-label text-start">
                      Teléfono:
                    </Label>
                    <Input
                      value={telefono}
                      id="telefono"
                      type="tel"
                      minLength={10}
                      maxLength={15}
                      onChange={(e) => {
                        const telefono = e.target.value.replace(/[^0-9]/g, "");
                        setTelefono(telefono);
                      }}
                      required
                    />
                  </Col>
                  <Col xl="6" sm="12">
                    <Label className="col-form-label text-start">Ciudad:</Label>
                    <Input
                      value={ciudad}
                      id="ciudad"
                      type="text"
                      onChange={(e) => {
                        setCiudad(e.target.value);
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Col xl="6" sm="12">
                    <Label className="col-form-label text-start">
                      Fuente de contacto:
                    </Label>
                    <Select
                      value={fuente}
                      options={fuentes}
                      placeholder={"Seleccione..."}
                      onChange={(fuente) => setFuente(fuente)}
                      required
                    />
                  </Col>
                  {userRole === "A" && (
                    <Col xl="6" sm="12">
                      <Label className="col-form-label text-start">
                        Asesor interno:
                      </Label>
                      <Select
                        options={internalAdvisors}
                        value={selectedInternalAdvisor}
                        placeholder={"Seleccionar asesor interno"}
                        onChange={(e) => setSelectedInternalAdvisor(e)}
                        isLoading={isLoading}
                      />
                    </Col>
                  )}
                </FormGroup>
              </div>
            </div>
          </div>
        </Col>
        <div className="w-100 d-flex justify-content-end btn-showcase">
          <Btn
            attrBtn={{
              color: "secondary",
              onClick: toggle,
              className: "m-r-10",
            }}
          >
            Cancelar
          </Btn>
          <Btn
            attrBtn={{
              type: "submit",
              color: "primary",
              disabled: isSubmitDisabled,
            }}
          >
            {isSubmitDisabled ? "Guardando..." : "Guardar"}
          </Btn>
        </div>
      </Row>
    </Form>
  );
};
export default BrokerEditForm;
