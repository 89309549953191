import React from "react";
import BrokersMenu from "../../Components/brokers/components/BrokersMenu";
import EditButton from "../../Components/shared/Buttons/EditButton";

export const brokersColumns = (handleEdit, handleDelete) => [
  {
    name: "Registro",
    selector: (row) => row.registerDate,
    sortable: true,
  },
  {
    name: "Nombre",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Correo",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Teléfono",
    selector: (row) => row.phone,
    sortable: true,
  },
  {
    name: "Asesor",
    selector: (row) => row.advisorName,
    sortable: true,
  },
  {
    minWidth: "80px",
    button: true,
    ignoreRowClick: true,
    allowOverflow: true,
    center: true,
    selector: (row) => null,
    cell: (row) => <EditButton row={row} onEditRow={handleEdit} />,
  },
  {
    minWidth: "50px",
    button: true,
    ignoreRowClick: true,
    allowOverflow: true,
    center: false,
    selector: (row) => null,
    cell: (row) => (
      <BrokersMenu size="small" row={row} onDeleteRow={handleDelete} />
    ),
  },
];
