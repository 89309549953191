import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment";
import {Container, Row, Col, Card, Form, Input, Label} from "reactstrap";
import {Breadcrumbs} from "../../AbstractElements";
import Request from "../../api/httpClient";
import DataTableComponent from "../../Components/Tables/DataTable/DataTableComponent";
import ToolbarButtons from "../../Components/shared/Buttons/ToolbarButtons";
import ModalContainer from "../../Components/shared/ModalContainer";
import FilterRangeDate from "../../Components/shared/Filters/FilterRangeDate";
import BrokerTabs from "../../Components/brokers/broker-tabs";
import {brokersColumns} from "../../Data/Table/BrokersColumns";

const request = new Request();

const STATUS = [
  {id: 1, label: "Asignados", value: "Asignados"},
  {id: 2, label: "No asignados", value: "No asignados"},
];

export default function Brokers() {
  const userId = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("Role");
  const [isAdmin, setIsAdmin] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [broker, setBroker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [internalAdvisors, setInternalAdvisors] = useState([]);
  // const [selectedInternalAdvisor, setSelectedInternalAdvisor] = useState(null);
  const [sources, setSources] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState(moment().clone().startOf("month").toDate());
  const [to, setTo] = useState(moment().clone().endOf("month").toDate());

  const [showModal, setShowModal] = useState({
    edit: false,
    delete: false,
    excel: false,
  });

  const handleModal = (type) => {
    setShowModal((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const fetchBrokers = async () => {
    setBrokers([]);
    setIsLoading(true);

    const data = {
      userId: isAdmin ? 0 : userId || 0,
    };

    const response = await request.get(`/brokers?userId=${data.userId}`);

    if (response && !response.error) {
      if (response && !response.empty) {
        setBrokers(response);
      } else {
        setBrokers([]);
        setIsLoading(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setIsLoading(false);
  };

  const fetchSources = async () => {
    setSources([]);
    const response = await request.get("/canales/get/all");
    if (response && !response.error) {
      if (response && !response.empty) {
        const fuentes = response.map((item) => {
          return {
            label: item.channelName,
            value: item.channelName,
          };
        });
        setSources(fuentes);
      } else {
        setSources([]);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
  };

  const fetchInternalAdvisors = async () => {
    setInternalAdvisors([]);

    const response = await request.get("/usuarios/internal-advisors");

    if (response && !response.error) {
      if (response && !response.empty) {
        setInternalAdvisors(response);
      } else {
        setInternalAdvisors([]);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
  };

  const handleEdit = (row) => {
    setBroker(row);
    handleModal("edit");
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: "Está seguro de eliminar este Broker?",
      text: "Se eliminará el broker: " + row.name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          id_prospecto: row.id,
        };

        const response = await request.post(
          "/prospectos/prospecto/delete",
          data
        );
        if (response && !response.error) {
          Swal.fire("Eliminado!", "Este broker ha sido eliminado", "success");
          fetchBrokers();
        } else {
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      }
    });
  };

  const tableColumns = brokersColumns(handleEdit, handleDelete);

  useEffect(() => {
    fetchSources();
    fetchInternalAdvisors();
  }, []);

  useEffect(() => {
    fetchBrokers();
  }, [isAdmin]);

  const filteredData = brokers
    .filter(
      (broker) =>
        !selectedStatus.value ||
        (selectedStatus.value === "Asignados" && broker.advisorId !== null) ||
        (selectedStatus.value === "No asignados" && broker.advisorId === null)
    )
    .filter(
      (prospect) =>
        !search ||
        Object.values(prospect).some((value) =>
          String(value).toLowerCase().includes(search.toLowerCase())
        )
    );

  const fetchReport = async () => {
    const data = {
      id_usuario: isAdmin ? 0 : userId || 0,
      from: moment(from).format("YYYY-MM-DD 00:00:00"),
      to: moment(to).format("YYYY-MM-DD 23:59:59"),
    };

    const response = await request.post("/brokers/report", data);
    return response;
  };

  return (
    <>
      <Breadcrumbs parent="Gestión" title="Brokers" mainTitle="Brokers" />
      <Container fluid={true}>
        <Card style={{minHeight: `calc(90vh - 140px)`, padding: "20px"}}>
          <Row className="row-gap-2 flex align-items-center">
            {userRole === "A" && (
              <>
                <Col lg="3" md="12" style={{zIndex: 3}}>
                  <Select
                    placeholder="Seleccionar estado"
                    options={STATUS}
                    onChange={(status) => setSelectedStatus(status || {})}
                    isClearable
                  />
                </Col>
                <Col lg="2" md="12">
                  <Row className="align-items-center">
                    <Col md="6">
                      <Label>Otros brokers:</Label>
                    </Col>
                    <Col md="5" className="'text-end icon-state switch-outline">
                      <Label className="switch">
                        <Input
                          type="checkbox"
                          onChange={() => setIsAdmin(!isAdmin)}
                          disabled={isLoading}
                        />
                        <span className="switch-state bg-info" />
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
            <Col sm="12">
              <Row className="m-b-10">
                <Col sm="7">
                  <div className="product-search feature-products">
                    <Form>
                      <div className="m-0 form-group search-product">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Buscar..."
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <i className="fa fa-search" />
                      </div>
                    </Form>
                  </div>
                </Col>
                <Col sm="5">
                  <ToolbarButtons
                    filename="Brokers"
                    showModal={() => handleModal("excel")}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Col sm="12">
            <div className="m-t-10 m-b-8">
              <DataTableComponent
                tableColumns={tableColumns}
                tableRows={filteredData}
                loadingData={isLoading}
                pagination={true}
                scrollHeight={"calc(80vh - 230px)"}
                minHeight={`calc(75vh - 230px)`}
              />
            </div>
          </Col>
        </Card>
      </Container>
      {showModal.edit && (
        <ModalContainer
          title="Editar broker"
          modal={true}
          open={() => handleModal("edit")}
          size="xl"
          styleBody={{minHeight: "300px"}}
        >
          <BrokerTabs
            id={broker.id}
            onClose={() => handleModal("edit")}
            reload={fetchBrokers}
            sources={sources}
            advisors={internalAdvisors}
          />
        </ModalContainer>
      )}
      {showModal.excel && (
        <ModalContainer
          modal={true}
          open={() => handleModal("excel")}
          size="md"
        >
          <FilterRangeDate
            onClose={() => handleModal("excel")}
            onExcelClick={fetchReport}
            filename="Brokers"
            to={to}
            from={from}
            setTo={setTo}
            setFrom={setFrom}
            // advisors={internalAdvisors}
            // setAdvisorsSelected={setSelectedInternalAdvisor}
            // otherUsers={isAdmin}
          />
        </ModalContainer>
      )}
    </>
  );
}
