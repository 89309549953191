import {
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Media,
} from "reactstrap";
import moment from "moment";
import useTracing from "../../Hooks/tracing/useTracing";
import ModalContainer from "../shared/ModalContainer";
import TracingCreate from "./components/Forms/TracingCreate";
import TracingEdit from "./components/Forms/TracingEdit";

const Tracing = ({prospect}) => {
  const {
    handleModal,
    isLoading,
    tracings,
    tracing,
    leftTab,
    setLeftTab,
    setTracing,
    showModal,
    reload,
  } = useTracing(prospect);

  return (
    <>
      <div className="p-20">
        {isLoading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <Row className="m-b-20">
              <Col sm="4">
                <h6>Prospecto: {prospect.name}</h6>
                <span>
                  <i className="fa fa-envelope-o"></i>
                  <code className="status f-12">Seguimiento</code>
                </span>
              </Col>
              <Col sm="7">
                <div className="text-end btn-showcase">
                  <Button
                    color="primary"
                    style={{width: 160}}
                    onClick={() => {
                      handleModal("create");
                    }}
                  >
                    <i className="fa fa-plus-square">
                      {" "}
                      <span style={{fontFamily: "Rubik, sans-serif"}}>
                        Seguimiento
                      </span>
                    </i>
                  </Button>
                </div>
              </Col>
            </Row>
            {tracings.length > 0 && (
              <Row>
                <Col sm="4" className="tabs-responsive-side">
                  <Nav className="flex-column nav-pills border-tab nav-left">
                    {tracings.map((item) => (
                      <NavItem
                        key={item.pkbinnacle}
                        className="alert alert-light"
                      >
                        <NavLink
                          className={
                            leftTab === item.pkbinnacle ? "active" : ""
                          }
                          onClick={() => {
                            setLeftTab(item.pkbinnacle);
                            setTracing(item);
                          }}
                        >
                          <Media>
                            <Media body>
                              <span className="f-w-600">{item.subject}</span>
                            </Media>
                            <div>
                              <span className="d-block">
                                {moment(item.capturedate).format("DD-MM-YYYY")}
                              </span>
                              <span className="d-block">
                                {moment(item.capturedate)
                                  .add(1, "hours")
                                  .format("hh:mm A")}
                              </span>
                            </div>
                          </Media>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </Col>
                <Col sm="7" className="alert alert-light">
                  <TabContent activeTab={leftTab}>
                    {tracings.map((item) => (
                      <TabPane
                        className="fade show"
                        tabId={item.pkbinnacle}
                        key={item.pkbinnacle}
                      >
                        <Media>
                          <Media body>
                            <span className="d-block">
                              <span className="f-w-600">Asunto: </span>
                              {item.subject}
                            </span>
                            {item.rEvento === 1 && (
                              <span className="d-block">
                                <span className="f-w-600">Recordatorio: </span>
                                {moment(item.fEvento).format(
                                  "DD-MM-YYYY hh:mm A"
                                )}
                              </span>
                            )}
                          </Media>
                          <div>
                            <span className="d-block">
                              <span className="badge badge-success">
                                {moment(item.capturedate)
                                  .add(1, "hours")
                                  .format("DD-MM-YYYY hh:mm A")}
                              </span>
                            </span>
                          </div>
                        </Media>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <hr
                            style={{
                              flex: 1,
                              borderColor: "darkgray",
                            }}
                          />
                          <Button
                            color="light"
                            size="xs"
                            active={false}
                            disabled={false}
                            outline={false}
                            onClick={() => {
                              handleModal("edit");
                            }}
                          >
                            Editar
                          </Button>
                          <hr
                            style={{
                              flex: 1,
                              borderColor: "darkgray",
                            }}
                          />
                        </div>

                        {item.detailnotes}
                      </TabPane>
                    ))}
                  </TabContent>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
      {showModal.create && (
        <ModalContainer
          modal={true}
          title={"Nuevo seguimiento"}
          open={() => handleModal("create")}
          size="lg"
        >
          <TracingCreate
            reload={reload}
            prospectId={prospect.id}
            handleModal={handleModal}
          />
        </ModalContainer>
      )}
      {showModal.edit && (
        <ModalContainer
          modal={true}
          title={"Editar seguimiento"}
          open={() => handleModal("edit")}
          size="lg"
        >
          <TracingEdit
            reload={reload}
            tracing={tracing}
            handleModal={handleModal}
          />
        </ModalContainer>
      )}
    </>
  );
};

export default Tracing;
