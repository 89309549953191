import React from "react";
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  MenuList,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ProspectoMenu = ({
  row,
  onDeleteRow,
  size,
  onUpdateStatusRow,
  onUpdateDesarrolloRow,
  onKommoModal,
}) => {
  const role = localStorage.getItem("Role");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  console.log("ProspectoMenu row", row);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      setAnchorEl(null);
      onDeleteRow(row);
    }
  };
  const updateStatusRow = () => {
    if (onUpdateStatusRow) {
      setAnchorEl(null);
      onUpdateStatusRow(row);
    }
  };
  const updateDesarrolloRow = () => {
    if (onUpdateDesarrolloRow) {
      setAnchorEl(null);
      onUpdateDesarrolloRow(row);
    }
  };

  const handleKommoModal = () => {
    if (onKommoModal) {
      setAnchorEl(null);
      onKommoModal(row);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={size}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-customized-button"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList>
          <MenuItem onClick={updateStatusRow}>
            <ListItemIcon>
              <SyncOutlinedIcon fontSize="small" color="info" />
            </ListItemIcon>
            <Typography variant="inherit">Cambiar etapa</Typography>
          </MenuItem>
          {role !== "S" && (
            <div>
              {row.desarrolloID === 5 || row.desarrolloID === 7 ? (
                <MenuItem onClick={handleKommoModal}>
                  <ListItemIcon>
                    <SendIcon fontSize="small" color="info" />
                  </ListItemIcon>
                  <Typography variant="inherit">Enviar a Kommo</Typography>
                </MenuItem>
              ) : null}
              <MenuItem onClick={updateDesarrolloRow}>
                <ListItemIcon>
                  <ContentCopyIcon fontSize="small" color="info" />
                </ListItemIcon>
                <Typography variant="inherit">
                  Añadir a otro desarrollo
                </Typography>
              </MenuItem>
            </div>
          )}
          <MenuItem onClick={deleteRow}>
            <ListItemIcon>
              <CloseOutlinedIcon fontSize="small" color="error" />
            </ListItemIcon>
            <Typography variant="inherit">Eliminar</Typography>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};

export default ProspectoMenu;
