import {useState, useEffect, useContext} from "react";
import DesarrollosContext from "../../_helper/Desarrollos";

export function useMenuItems() {
  const [role] = useState(localStorage.getItem("Role"));
  const [menuItems, setMenuItems] = useState(
    role !== "M"
      ? [
          {
            menutitle: "Gestión",
            menucontent: "Dashboards,Widgets,Gestión",
            Items: [
              {
                title: "Inicio",
                icon: "stroke-home",
                type: "link",
                path: `/dashboard`,
              },
              role === "A" && {
                title: "Buscador Global",
                icon: "stroke-search",
                type: "link",
                path: `/buscador`,
              },
              (role === "A" || role === "V") && {
                title: "Etapas",
                icon: "stroke-widget",
                type: "link",
                path: `/etapas`,
              },
              {
                title: "Prospectos",
                icon: "stroke-builders",
                type: "link",
                path: `/prospectos`,
              },
              (role === "A" || role === "V") && {
                title: "Brokers",
                icon: "stroke-builders",
                type: "link",
                path: `/brokers`,
              },
              role === "A" && {
                title: "Usuarios",
                icon: "stroke-user",
                type: "link",
                path: `/usuarios`,
              },
              {
                title: "Recordatorios",
                icon: "stroke-chat",
                type: "link",
                path: `/recordatorios`,
              },
              role === "A" && {
                title: "Embudos",
                icon: "stroke-file",
                type: "link",
                path: `/embudos`,
              },
              role === "A" && {
                title: "Desarrollos",
                icon: "others",
                type: "sub",
                active: false,

                children: [
                  {
                    title: "Gestión",
                    type: "link",
                    path: `/desarrollos`,
                  },
                  {
                    title: "Disponibilidad",
                    type: "link",
                    path: `/desarrollos/disponibilidad`,
                  },
                ],
              },
            ].filter(Boolean),
          },
          role === "A" && {
            menutitle: "Zapier",
            menucontent: "Zapier",
            Items: [
              {
                title: "Zapier",
                icon: "stroke-sample-page",
                type: "link",
                path: `/zapier`,
              },
            ].filter(Boolean),
          },
          role === "A"
            ? {
                menutitle: "Herramientas",
                menucontent: "Herramientas",
                Items: [
                  {
                    title: "Canales",
                    icon: "stroke-sample-page",
                    type: "link",
                    path: `/canales`,
                  },
                  {
                    title: "Motivos",
                    icon: "stroke-sample-page",
                    type: "link",
                    path: `/motivos`,
                  },
                  {
                    title: "Recuperación",
                    icon: "stroke-email",
                    type: "link",
                    path: `/recuperacion`,
                  },
                ].filter(Boolean),
              }
            : null,
          {
            menutitle: "Externos",
            menucontent: "Enlaces externos, Google Drive",
            Items: [
              {
                title: "Disponibilidad (ext)",
                icon: "file",
                type: "sub",
                active: false,
              },
              {
                title: "Kit de ventas (ext)",
                icon: "file",
                type: "sub",
                active: false,
              },
            ].filter(Boolean),
          },
          role === "A"
            ? {
                menutitle: "Marketing",
                menucontent: "Reportes de marketing",
                Items: [
                  {
                    title: "Reportes",
                    icon: "stroke-table",
                    type: "link",
                    path: `/reportes`,
                  },
                ].filter(Boolean),
              }
            : null,
        ].filter(Boolean)
      : [
          {
            menutitle: "Gestión",
            menucontent: "Dashboards,Widgets,Gestión",
            Items: [
              {
                title: "Inicio",
                icon: "stroke-home",
                type: "link",
                path: `/dashboard`,
              },
            ].filter(Boolean),
          },

          {
            menutitle: "Marketing",
            menucontent: "Reportes de marketing",
            Items: [
              {
                title: "Reportes",
                icon: "stroke-table",
                type: "link",
                path: `/reportes`,
              },
            ].filter(Boolean),
          },
        ].filter(Boolean)
  );

  const {desarrollos} = useContext(DesarrollosContext);

  useEffect(() => {
    const childrenDisponibilidad = desarrollos.map((desarrollo) => ({
      path: desarrollo.gdrive_disponibilidad,
      title: desarrollo.nomDesarrollo,
      type: "external-link",
    }));

    const childrenKitHerramientas = desarrollos.map((desarrollo) => ({
      path: desarrollo.gdrive_kitventas,
      title: desarrollo.nomDesarrollo,
      type: "external-link",
    }));

    const newMenuItems = menuItems.map((menuItem) => {
      if (menuItem.menutitle === "Externos") {
        const newItems = menuItem.Items.map((item) => {
          if (item.title === "Disponibilidad (ext)") {
            return {...item, children: childrenDisponibilidad};
          } else if (item.title === "Kit de ventas (ext)") {
            return {...item, children: childrenKitHerramientas};
          } else {
            return item;
          }
        });

        return {...menuItem, Items: newItems};
      } else {
        return menuItem;
      }
    });

    setMenuItems(newMenuItems);
  }, [desarrollos]);

  return menuItems;
}
