import {useState} from "react";
import moment from "moment";
import {toast} from "react-toastify";
import Request from "../../api/httpClient";

const request = new Request();

const EMINDER = [
  {value: 0, label: "No"},
  {value: 1, label: "Si"},
];

const useTracingCreate = ({reload, prospectId, handleModal}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventDate, setEventDate] = useState(new Date());
  const [eventTime, setEventTime] = useState(new Date());
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");
  const [selectedReminder, setSelectedReminder] = useState(EMINDER[0]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const fechaHoy = moment().format("YYYY-MM-DD");
    const registerDate = new Date();

    const data = {
      id_prospecto: prospectId,
      fechaVencimiento: moment(fechaHoy).add(45, "days").format("YYYY-MM-DD"),
      id_usuario: +localStorage.getItem("user_id") || 0,
      asunto: subject,
      detalles: details,
      rEvento: selectedReminder.value,
      fEvento: moment(eventDate).format("YYYY-MM-DD"),
      hEvento: moment(eventTime).format("HH:mm:ss"),
      register_date: registerDate,
    };

    const response = await request.post(
      "/prospectos/prospecto/seguimiento/create",
      data
    );

    if (response && !response.error) {
      handleModal("create");
      setIsLoading(false);
      reload("Seguimiento creado con éxito");
    } else {
      setIsLoading(false);
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  return {
    EMINDER,
    isLoading,
    eventDate,
    setEventDate,
    eventTime,
    setEventTime,
    subject,
    setSubject,
    details,
    setDetails,
    selectedReminder,
    setSelectedReminder,
    handleSubmit,
  };
};

export {EMINDER, useTracingCreate};
