import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Select from "react-select";
import moment from "moment";
import {Container, Row, Col, Card, Form, Input, Media, Label} from "reactstrap";
import Swal from "sweetalert2";
import {Breadcrumbs} from "../../AbstractElements";
import Request from "../../api/httpClient";
import DataTableComponent from "../../Components/Tables/DataTable/DataTableComponent";
import ToolbarButtons from "../../Components/shared/Buttons/ToolbarButtons";
import {prospectsColumns} from "../../Data/Table/ProspectsColumns";
import ModalContainer from "../../Components/shared/ModalContainer";
import FilterRangeDate from "../../Components/shared/Filters/FilterRangeDate";
import {recoveryEmail} from "../../Data/options";
import CreateForm from "../../Components/prospects/create-form";
import CloneForm from "../../Components/prospects/clone-form";
import useKommo from "../../Hooks/useKommo";
import KommoForm from "../../Components/prospects/kommo-form";
import ProspectTabs from "../../Components/prospects/prospect-tabs";
const request = new Request();

export default function Prospects() {
  const userId = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("Role");
  const {handleKommo} = useKommo();
  const [isAdmin, setIsAdmin] = useState(false);
  const [prospects, setProspects] = useState([]);
  const [prospect, setProspect] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [developments, setDevelopments] = useState([]);
  const [selectedDevelopment, setSelectedDevelopment] = useState({});
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState({});
  const [sources, setSources] = useState([]);
  const [selectedRecoveryEmail, setSelectedRecoveryEmail] = useState(-1);
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState(moment().clone().startOf("month").toDate());
  const [to, setTo] = useState(moment().clone().endOf("month").toDate());
  const [internalAdvisors, setInternalAdvisors] = useState([]);
  const [selectedInternalAdvisor, setSelectedInternalAdvisor] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [stagesList, setStagesList] = useState({});
  const [showModal, setShowModal] = useState({
    create: false,
    edit: false,
    clone: false,
    excel: false,
    kommo: false,
  });

  const handleModal = (type) => {
    setShowModal((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const fetchProspects = async () => {
    setProspects([]);
    setIsLoading(true);

    const data = {
      userId: isAdmin ? 0 : userId || 0,
    };

    const response = await request.get(`/prospects?userId=${data.userId}`);

    if (response && !response.error) {
      if (response && !response.empty) {
        setProspects(response);
      } else {
        setProspects([]);
        setIsLoading(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setIsLoading(false);
  };

  const fetchDevelopments = async () => {
    setDevelopments([]);
    const response = await request.get("/desarrollos/get/all");
    if (response && !response.error) {
      if (response) {
        const options = response.map((development) => ({
          id: development.IDdesarrollo,
          value: development.nomDesarrollo,
          label: development.nomDesarrollo,
        }));

        setDevelopments(options);
      } else {
        setDevelopments([]);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
  };

  const fetchStages = async () => {
    setStages([]);
    const response = await request.get("/embudos/get/all");
    if (response && !response.error) {
      if (response) {
        const options = response.map((item) => {
          return {
            id: item.funnelId,
            label: item.funnelName,
            value: item.funnelName,
          };
        });
        const stagesList = response.reduce((acc, stage) => {
          acc[stage.funnelId] = stage.funnelName;
          return acc;
        }, {});

        setStages(options);
        setStagesList(stagesList);
      } else {
        setStages([]);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
  };

  const fetchReasons = async () => {
    setReasons([]);
    const response = await request.get("/motivos/get/all");
    if (response && !response.error) {
      if (response && !response.empty) {
        if (response.length > 0) {
          let reasons = {
            0: "Sin comentario",
          };

          response.forEach((item) => {
            reasons[item.reasonId] = item.reasonName;
          });

          reasons["otros"] = "Otros...";

          setReasons(reasons);
        }
      } else {
        setReasons([]);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
  };

  const fetchSources = async () => {
    setSources([]);
    const response = await request.get("/canales/get/all");
    if (response && !response.error) {
      if (response && !response.empty) {
        const fuentes = response.map((item) => {
          return {
            label: item.channelName,
            value: item.channelName,
          };
        });
        setSources(fuentes);
      } else {
        setSources([]);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
  };

  const fetchInternalAdvisors = async () => {
    setInternalAdvisors([]);

    const response = await request.get("/usuarios/internal-advisors");

    if (response && !response.error) {
      if (response && !response.empty) {
        setInternalAdvisors(response);
      } else {
        setInternalAdvisors([]);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
  };

  const handleEdit = (row) => {
    setProspect(row);
    handleModal("edit");
  };

  const handleRemove = (row) => {
    Swal.fire({
      title: "Está seguro de eliminar este prospecto?",
      text: "Se eliminará el prospecto: " + row.client,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          id_prospecto: row.pkclient,
        };

        const response = await request.post(
          "/prospectos/prospecto/delete",
          data
        );
        if (response && !response.error) {
          Swal.fire(
            "Operación completada!",
            "Se cambió la etapa del prospecto",
            "success"
          );
          fetchProspects();
        } else {
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      }
    });
  };

  const handleSendKommo = (row) => {
    setProspect(row);
    handleModal("kommo");
  };

  const handleChangeStatus = (row) => {
    Swal.fire({
      title: "Cambio de etapa",
      text: "Se cambiará la etapa de: " + row.client,
      icon: "warning",
      input: "select",
      inputOptions: stagesList,
      inputValue: row.funnelId,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cambiar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Razón del cambio",
          text: "Puede dejar un comentario sobre el cambio de estado",
          icon: "warning",
          input: "select",
          inputValue: 0,
          inputOptions: reasons,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then(async (reasonResult) => {
          if (reasonResult.isConfirmed) {
            if (reasonResult.value === "otros") {
              Swal.fire({
                title: "Ingrese su razón",
                input: "textarea",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
                reverseButtons: true,
              }).then(async (textResult) => {
                if (textResult.isConfirmed) {
                  let data = {
                    id_prospecto: row.pkclient,
                    nombre_prospecto: row.client,
                    email_prospecto: row.emailclient,
                    funnelId: result.value,
                    reason: textResult.value,
                    id_usuario: localStorage.getItem("user_id") || 0,
                    id_desarrollo: selectedDevelopment.id || 0,
                    actividad: JSON.stringify({
                      value:
                        "Se realizo un cambio de embudo de <strong>" +
                        row.funnelName +
                        "</strong> a <strong>" +
                        stagesList[result.value] +
                        "</strong>.",
                      details: {
                        from: row.funnelName,
                        to: stagesList[result.value],
                      },
                    }),
                  };

                  const response = await request.post(
                    "/prospectos/prospecto/status",
                    data
                  );

                  if (response && !response.error) {
                    if (
                      result.value === "7" &&
                      (row.desarrolloID === 5 || row.desarrolloID === 7)
                    ) {
                      await handleKommo(row);
                    }
                    Swal.fire(
                      "Operación completada!",
                      "Se cambió la etapa del prospecto",
                      "success"
                    );
                    fetchProspects();
                  } else {
                    toast.error(response.message, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      autoClose: 2000,
                    });
                  }
                }
              });
            } else {
              let data = {
                id_prospecto: row.pkclient,
                nombre_prospecto: row.client,
                email_prospecto: row.emailclient,
                funnelId: result.value,
                reason:
                  reasonResult.value === "0" ? "" : reasons[reasonResult.value],
                id_usuario: localStorage.getItem("user_id") || 0,
                id_desarrollo: row.desarrolloID,
                actividad: JSON.stringify({
                  value:
                    "Se realizo un cambio de embudo de <strong>" +
                    row.funnelName +
                    "</strong> a <strong>" +
                    stagesList[result.value] +
                    "</strong>.",
                  details: {
                    from: row.funnelName,
                    to: stagesList[result.value],
                  },
                }),
              };

              const response = await request.post(
                "/prospectos/prospecto/status",
                data
              );
              if (response && !response.error) {
                if (
                  result.value === "7" &&
                  (row.desarrolloID === 5 || row.desarrolloID === 7)
                ) {
                  await handleKommo(row);
                }
                Swal.fire(
                  "Operación completada!",
                  "Se cambió la etapa del prospecto",
                  "success"
                );
                fetchProspects();
              } else {
                toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
                });
              }
            }
          }
        });
      }
    });
  };

  const handleClone = (row) => {
    setProspect(row);
    handleModal("clone");
  };

  const tableColumns = prospectsColumns(
    handleEdit,
    handleRemove,
    handleChangeStatus,
    handleClone,
    handleSendKommo
  );

  useEffect(() => {
    fetchDevelopments();
    fetchStages();
    fetchSources();
    fetchReasons();
    fetchInternalAdvisors();
  }, []);

  useEffect(() => {
    fetchProspects();
  }, [isAdmin]);

  const filteredData = prospects
    .filter(
      ({nomDesarrollo, funnelName}) =>
        (!selectedDevelopment.value ||
          nomDesarrollo === selectedDevelopment.value) &&
        (!selectedStage.value || funnelName === selectedStage.value)
    )
    .filter(
      (prospect) =>
        !search ||
        Object.values(prospect).some((value) =>
          String(value).toLowerCase().includes(search.toLowerCase())
        )
    );

  const fetchReport = async () => {
    const data = {
      id_usuario:
        isAdmin || (userRole === "A" && selectedInternalAdvisor)
          ? selectedInternalAdvisor?.id || 0
          : localStorage.getItem("user_id") || 0,
      id_desarrollo: selectedDevelopment.id || 0,
      id_embudo: selectedStage.id || 0,
      from: moment(from).format("YYYY-MM-DD 00:00:00"),
      to: moment(to).format("YYYY-MM-DD 23:59:59"),
    };

    const response = await request.post("/prospectos/report", data);
    if (response && !response.error) {
      toast.success("Reporte generado", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      setFrom(moment().clone().startOf("month").toDate());
      setTo(moment().clone().endOf("month").toDate());
      setSelectedInternalAdvisor(null);
    }

    if (response && response.error) {
      toast.warning(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      setFrom(moment().clone().startOf("month").toDate());
      setTo(moment().clone().endOf("month").toDate());
      setSelectedInternalAdvisor(null);
    }

    return response;
  };

  return (
    <>
      <Breadcrumbs parent="Gestión" title="Prospectos" mainTitle="Prospectos" />
      <Container fluid={true}>
        <Card style={{minHeight: `calc(90vh - 140px)`, padding: "20px"}}>
          <Row className="row-gap-2 flex align-items-center">
            <Col lg="3" md="12">
              <Select
                placeholder="Seleccionar desarrollo"
                options={developments}
                onChange={(development) =>
                  setSelectedDevelopment(development || {})
                }
                isClearable
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 3,
                  }),
                }}
              />
            </Col>
            <Col lg="3" md="12">
              <Select
                placeholder="Seleccionar etapa"
                options={stages}
                onChange={(stage) => setSelectedStage(stage || {})}
                isClearable
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 3,
                  }),
                }}
              />
            </Col>
            <Col>
              <Media className="align-items-center">
                <Label className="col-form-label text-start">
                  Correos de recuperación: &nbsp;
                </Label>
                <Media>
                  <div className="m-checkbox-inline custom-radio-ml d-flex">
                    <div className="radio radio-primary">
                      {recoveryEmail.map((option, index) => (
                        <div key={index} className="radio radio-primary">
                          <Input
                            id={`radioinline${option.value}`}
                            type="radio"
                            name="radio"
                            checked={selectedRecoveryEmail === option.value}
                            onChange={() =>
                              setSelectedRecoveryEmail(option.value)
                            }
                          />
                          <Label
                            className="mb-0"
                            for={`radioinline${option.value}`}
                          >
                            {option.label}
                          </Label>
                        </div>
                      ))}
                    </div>
                  </div>
                </Media>
              </Media>
            </Col>
            {userRole === "A" && (
              <Col lg="2" md="12">
                <Row className="align-items-center">
                  <Col md="6">
                    <Label>Otros prospecto:</Label>
                  </Col>
                  <Col md="5" className="'text-end icon-state switch-outline">
                    <Label className="switch">
                      <Input
                        type="checkbox"
                        onChange={() => setIsAdmin(!isAdmin)}
                        disabled={isLoading}
                      />
                      <span className="switch-state bg-info" />
                    </Label>
                  </Col>
                </Row>
              </Col>
            )}
            <Col sm="12">
              <Row className="m-b-10">
                <Col sm="7">
                  <div className="product-search feature-products">
                    <Form>
                      <div className="m-0 form-group search-product">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Buscar..."
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <i className="fa fa-search" />
                      </div>
                    </Form>
                  </div>
                </Col>
                <Col sm="5">
                  <ToolbarButtons
                    filename="Prospectos"
                    toggleCreate={() => handleModal("create")}
                    showModal={() => handleModal("excel")}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Col sm="12">
            <div className="m-t-10 m-b-8">
              <DataTableComponent
                tableColumns={tableColumns}
                tableRows={filteredData}
                loadingData={isLoading}
                pagination={true}
                scrollHeight={"calc(80vh - 230px)"}
                minHeight={`calc(75vh - 230px)`}
              />
            </div>
          </Col>
        </Card>
      </Container>

      {showModal.create && (
        <ModalContainer
          title="Nuevo prospecto"
          modal={true}
          open={() => handleModal("create")}
          size="xl"
        >
          <CreateForm
            onClose={() => handleModal("create")}
            reload={fetchProspects}
            developments={developments}
            sources={sources}
            stages={stages}
          />
        </ModalContainer>
      )}
      {showModal.edit && (
        <ModalContainer
          title="Editar prospecto"
          modal={true}
          open={() => handleModal("edit")}
          size="xl"
          styleBody={{minHeight: "600px"}}
        >
          <ProspectTabs
            id={prospect.pkclient}
            onClose={() => handleModal("edit")}
            reload={fetchProspects}
            sources={sources}
            stages={stages}
          />
        </ModalContainer>
      )}
      {showModal.clone && (
        <ModalContainer
          title="Añadir prospecto a otro desarrollo"
          modal={true}
          open={() => handleModal("clone")}
          size="md"
        >
          <CloneForm
            onClose={() => handleModal("clone")}
            reload={fetchProspects}
            prospect={prospect}
            developments={developments}
            stages={stages}
          />
        </ModalContainer>
      )}
      {showModal.excel && (
        <ModalContainer
          modal={true}
          open={() => handleModal("excel")}
          size="md"
        >
          <FilterRangeDate
            onClose={() => handleModal("excel")}
            onExcelClick={fetchReport}
            filename="Prospectos"
            to={to}
            from={from}
            setTo={setTo}
            setFrom={setFrom}
            advisors={internalAdvisors}
            setAdvisorsSelected={setSelectedInternalAdvisor}
            otherUsers={isAdmin}
          />
        </ModalContainer>
      )}
      {showModal.kommo && (
        <ModalContainer
          modal={true}
          open={() => handleModal("kommo")}
          size="md"
        >
          <KommoForm
            prospect={prospect}
            onClose={() => handleModal("kommo")}
            reload={fetchProspects}
          />
        </ModalContainer>
      )}
    </>
  );
}
