import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {Form, Input, Label, Row, ModalFooter, Button} from "reactstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import Select from "react-select";
import {toast} from "react-toastify";
import {ratings} from "../../Data/options";
import moment from "moment";
import ModalContainer from "../shared/ModalContainer";
import KommoForm from "./kommo-form";

const request = new Request();

export default function EditProspect({
  prospect,
  onClose,
  reload,
  sources,
  stages,
  fetchProspect,
}) {
  const userId = localStorage.getItem("user_id") || 0;
  const [isSubmit, setIsSubmit] = useState(false);
  const [apartments, setApartments] = useState([]);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [loadingApartments, setLoadingApartments] = useState(false);
  const [carruselDate, setCarruselDate] = useState([]);
  const [loadingCarrusel, setLoadingCarrusel] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [showModal, setShowModal] = useState({
    kommo: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    comments: "",
    budget: "",
  });

  useEffect(() => {
    if (prospect) {
      setFormData({
        name: prospect.name || "",
        email: prospect.email || "",
        phone: prospect.phone || "",
        city: prospect.city || "",
        comments: prospect.comments || "",
        budget: prospect.budget || "",
      });
      setSelectedStage(stages.find((stage) => stage.id === prospect.stageId));
      setSelectedSource(
        sources.find((source) => source.value === prospect.origin)
      );
      setSelectedRating(
        ratings.find((rating) => rating.value === prospect.qualification)
      );
      if (prospect.interesting) {
        setSelectedApartment({
          label: prospect.interesting,
          value: prospect.interesting,
        });
      }
    }
  }, [prospect]);

  useEffect(() => {
    if (prospect.developmentId) {
      getDepartamentos();
    }
  }, [prospect.developmentId]);

  const handleModal = (type) => {
    setShowModal((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const fetchCarrouselDates = async () => {
    setCarruselDate([]);
    setLoadingCarrusel(true);

    const data = {
      prospectId: prospect.id,
      developmentId: prospect.developmentId,
    };

    const response = await request.post("/prospectos/carrusel-date", data);

    if (response && !response.error) {
      setCarruselDate(response);
    } else {
      console.error(response.message || "Error al cargar fechas de carrusel");
      setCarruselDate([]);
    }
    setLoadingCarrusel(false);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsSubmit(true);

      const {name, email, phone, city, comments, budget} = formData;

      if (!email) {
        toast.warning("El correo es requerido", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      if (!phone) {
        toast.warning("El teléfono es requerido", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      let normalizedPhone = phone;
      if (phone.length === 12 && phone.startsWith("52")) {
        normalizedPhone = phone.slice(2);
      } else if (phone.length === 13 && phone.startsWith("521")) {
        normalizedPhone = phone.slice(3);
      } else if (phone.length === 14 && phone.startsWith("5201")) {
        normalizedPhone = phone.slice(4);
      }

      if (normalizedPhone.startsWith("0")) {
        toast.warning("Ningún número de teléfono puede iniciar con ceros", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      if (normalizedPhone.length < 10) {
        toast.warning("El número de teléfono debe tener al menos 10 dígitos", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      const trimmedName = name.trim();
      const cleanedEmail = email.replace(/\s+/g, "");
      const editionDate = new Date()
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      let data = {
        name: trimmedName,
        email: cleanedEmail,
        phone: normalizedPhone,
        city: city,
        developmentId: prospect.developmentId,
        interest: selectedApartment ? selectedApartment.label : null,
        budget: budget,
        qualification: selectedRating.value,
        origin: selectedSource.value,
        stageId: selectedStage.id,
        comments: comments,
        editDate: editionDate,
        editorId: userId,
      };

      const response = await request.put(`/prospects/${prospect.id}`, data);

      if (response && !response.error) {
        setIsSubmit(false);
        onClose();
        reload();
        toast.success(response.message || "Prospecto editado correctamente", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      } else {
        setIsSubmit(false);
        toast.error(
          response.message || "Hubo un error al editar el prospecto",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      console.error(error);
      setIsSubmit(false);
      toast.error("Hubo un error al procesar la solicitud", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getDepartamentos = async () => {
    setApartments([]);
    setLoadingApartments(true);
    const data = {
      id_desarrollo: prospect.developmentId,
    };
    const response = await request.post("/prospectos/get/departamentos", data);
    if (response && !response.error) {
      if (response && !response.empty) {
        setApartments(
          response.map((dpto) => {
            return {
              id: dpto.pkdepto,
              value: dpto.depto,
              label: dpto.depto,
            };
          })
        );
      } else {
        setApartments([]);
        setLoadingApartments(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingApartments(false);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: field === "phone" ? value.replace(/[^0-9]/g, "") : value,
    }));
  };

  useEffect(() => {
    fetchCarrouselDates();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="p-10">
          <Col xl="4" sm="12">
            <Label className="col-form-label text-start">Nombre:</Label>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              required
            />
          </Col>
          <Col xl="4" sm="12">
            <Label className="col-form-label text-start">Email:</Label>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
              required
            />
          </Col>
          <Col xl="4" sm="12">
            <Label className="col-form-label text-start">Teléfono:</Label>
            <Input
              type="tel"
              name="phone"
              minLength={10}
              maxLength={15}
              value={formData.phone}
              onChange={(e) => handleInputChange("phone", e.target.value)}
              required
            />
          </Col>
          <Col xl="4" sm="12">
            <Label className="col-form-label text-start">Ciudad:</Label>
            <Input
              type="text"
              name="city"
              value={formData.city}
              onChange={(e) => handleInputChange("city", e.target.value)}
            />
          </Col>
          <Col xl="4" sm="12">
            <Label className="col-form-label text-start">Desarrollo:</Label>
            <Input
              value={prospect.developmentName}
              id="desarrollo"
              type="text"
              disabled
            />
          </Col>
          <Col xl="4" sm="12" style={{zIndex: 999}}>
            <Label className="col-form-label text-start">Interesados en:</Label>
            <Select
              options={apartments}
              value={selectedApartment}
              onChange={(apartment) => setSelectedApartment(apartment)}
              placeholder={"Seleccione..."}
              isLoading={loadingApartments}
              isClearable
            />
          </Col>
          <Col xl="4" sm="12">
            <Label className="col-form-label text-start">Presupuesto:</Label>
            <Input
              type="text"
              name="budget"
              value={formData.budget}
              onChange={(e) => handleInputChange("budget", e.target.value)}
            />
          </Col>
          <Col xl="4" sm="12" style={{zIndex: 998}}>
            <Label className="col-form-label text-start">Calificación:</Label>
            <Select
              placeholder="Seleccione..."
              value={selectedRating}
              options={ratings}
              onChange={(rating) => setSelectedRating(rating)}
              required
            />
          </Col>
          <Col xl="4" sm="12" style={{zIndex: 997}}>
            <Label className="col-form-label text-start">
              Fuente de contacto:
            </Label>
            <Select
              placeholder="Seleccione..."
              value={selectedSource}
              options={sources}
              onChange={(source) => setSelectedSource(source)}
              required
            />
          </Col>
          <Col xl="4" sm="12" style={{zIndex: 996}}>
            <Label className="col-form-label text-start">Etapa:</Label>
            <Select
              placeholder="Seleccione..."
              value={selectedStage}
              options={stages}
              onChange={(stage) => setSelectedStage(stage)}
              required
            />
          </Col>
          {prospect.developmentId === 5 || prospect.developmentId === 7 ? (
            <Col xl="4" sm="12" style={{marginTop: "auto"}}>
              <Button
                color="info"
                onClick={() => handleModal("kommo")}
                style={{
                  minWidth: 120,
                  marginRight: 10,
                }}
              >
                Enviar a Kommo
              </Button>
            </Col>
          ) : null}
          <Row>
            <Col sm="6">
              <Col sm="12">
                <Label className="col-form-label text-start">
                  Correos de Recuperación:
                </Label>
              </Col>
              <Row>
                <Col sm="6">
                  <div className="checkbox">
                    <Input
                      id="email_recuperacion1"
                      type="checkbox"
                      checked={
                        prospect.emailRecuperacion1_fecha !== null
                          ? true
                          : false
                      }
                      readOnly
                    />
                    <Label className="text-muted" for="email_recuperacion1">
                      1er Correo
                    </Label>
                  </div>
                </Col>
                <Col sm="6">
                  {prospect.emailRecuperacion1_fecha != null && (
                    <Label className="text-muted p-10 font-success">
                      Enviado el{" "}
                      {moment(prospect.emailRecuperacion1_fecha).format(
                        "DD-MM-YYYY"
                      )}
                    </Label>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <div className="checkbox">
                    <Input
                      id="email_recuperacion2"
                      type="checkbox"
                      checked={
                        prospect.emailRecuperacion2_fecha !== null
                          ? true
                          : false
                      }
                      readOnly
                    />
                    <Label className="text-muted" for="email_recuperacion2">
                      2do Correo
                    </Label>
                  </div>
                </Col>
                <Col sm="6">
                  {prospect.emailRecuperacion2_fecha != null && (
                    <Label className="text-muted p-10 font-success">
                      Enviado el{" "}
                      {moment(prospect.emailRecuperacion2_fecha).format(
                        "DD-MM-YYYY"
                      )}
                    </Label>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <div className="checkbox">
                    <Input
                      id="email_recuperacion3"
                      type="checkbox"
                      checked={
                        prospect.emailRecuperacion3_fecha !== null
                          ? true
                          : false
                      }
                      readOnly
                    />
                    <Label className="text-muted" for="email_recuperacion3">
                      3er Correo
                    </Label>
                  </div>
                </Col>
                <Col sm="6">
                  {prospect.emailRecuperacion3_fecha != null && (
                    <Label className="text-muted p-10 font-success">
                      Enviado el{" "}
                      {moment(prospect.emailRecuperacion3_fecha).format(
                        "DD-MM-YYYY"
                      )}
                    </Label>
                  )}
                </Col>
              </Row>
            </Col>
            {!loadingCarrusel && carruselDate.length > 0 && (
              <Col sm="6">
                <Col sm="12">
                  <Label className="col-form-label text-start">
                    Entró por carruel el:
                  </Label>
                </Col>
                {carruselDate.map((date, index) => (
                  <Row key={index}>
                    <Col sm="6">
                      <Label className="text-primary">
                        {moment(date.Fecha).format("DD-MM-YYYY")}
                      </Label>
                    </Col>
                  </Row>
                ))}
              </Col>
            )}
          </Row>
          <Col xl="12">
            <Label className="col-form-label text-start">Comentarios:</Label>
            <Input
              type="textarea"
              name="comments"
              value={formData.comments}
              onChange={(e) => handleInputChange("comments", e.target.value)}
              rows="2"
            />
          </Col>
        </Row>
        <ModalFooter>
          <Btn attrBtn={{color: "secondary", onClick: onClose}}>Cancelar</Btn>
          <Btn
            attrBtn={{
              type: "submit",
              color: "primary",
              disabled: isSubmit,
            }}
          >
            {isSubmit ? "Guardando..." : "Guardar"}
          </Btn>
        </ModalFooter>
      </Form>
      {showModal.kommo && (
        <ModalContainer
          modal={true}
          open={() => handleModal("kommo")}
          size="md"
        >
          <KommoForm
            prospect={{
              pkclient: prospect.id,
              client: formData.name,
              emailclient: formData.email,
              phoneclient: formData.phone,
              city: formData.city,
              nomDesarrollo: prospect.developmentName,
              desarrolloID: prospect.developmentId,
              funnelName: prospect.stageName,
            }}
            onClose={() => handleModal("kommo")}
            reload={fetchProspect}
          />
        </ModalContainer>
      )}
    </>
  );
}
