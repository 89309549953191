import React from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {Form, FormGroup, Input, Label, Row, Col, Button} from "reactstrap";
import {useTracingCreate} from "../../../../Hooks/tracing/useTracingCreate";

const TracingCreate = ({reload, prospectId, handleModal}) => {
  const {
    EMINDER,
    isLoading,
    eventDate,
    setEventDate,
    eventTime,
    setSubject,
    setEventTime,
    setDetails,
    selectedReminder,
    setSelectedReminder,
    handleSubmit,
  } = useTracingCreate({reload, prospectId, handleModal});

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Row>
          <Col xl="4">
            <Label className="col-form-label text-start">Recordar:</Label>
            <Select
              options={EMINDER}
              defaultValue={selectedReminder}
              placeholder={"Seleccionar estatus"}
              onChange={(e) => setSelectedReminder(e)}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 3,
                }),
              }}
            />
          </Col>
          <Col xl="4">
            <Label className="col-form-label text-start">
              Fecha del evento:
            </Label>
            <DatePicker
              className="form-control datetimepicker-input digits"
              selected={eventDate}
              onChange={(fecha) => setEventDate(fecha)}
              dateFormat="dd/MM/yyyy"
              required
            />
          </Col>
          <Col xl="4">
            <Label className="col-form-label text-start">
              Hora del evento:
            </Label>
            <DatePicker
              className="form-control datetimepicker-input digits"
              selected={eventTime}
              onChange={(hora) => setEventTime(hora)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              required
            />
          </Col>
          <Col xl="12">
            <Label className="col-form-label text-start">Asunto:</Label>
            <Input
              id="asunto"
              type="text"
              onChange={(e) => {
                setSubject(e.target.value);
              }}
              required
            />
          </Col>
          <Col xl="12">
            <Label className="col-form-label text-start">Detalles:</Label>
            <Input
              id="detalles"
              type="textarea"
              className="form-control"
              rows="2"
              onChange={(e) => {
                setDetails(e.target.value);
              }}
              required
            />
          </Col>
          <div className="w-100 d-flex justify-content-end btn-showcase m-t-10">
            <Button
              className="m-r-10"
              color="secondary"
              onClick={() => handleModal("create")}
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={isLoading}>
              {isLoading ? "Guardando..." : "Guardar"}
            </Button>
          </div>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default TracingCreate;
