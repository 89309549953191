import {toast} from "react-toastify";
import Request from "../api/httpClient";

const request = new Request();

/**
 * Custom hook for handling the logic of creating/verifying leads in Kommo.
 *
 * @returns {Object} An object with the `handleKommo` function.
 *
 * @example
 * import useKommo from "path/to/useKommo";
 *
 * const MyComponent = () => {
 *   const { handleKommo } = useKommo();
 *
 *   const sendDataToKommo = () => {
 *     const prospectData = {
 *       pkclient: 1,
 *       client: "John Doe",
 *       emailclient: "john@example.com",
 *       phoneclient: "1234567890",
 *       city: "Example City",
 *       nomDesarrollo: "Towers 3",
 *     };
 *
 *     handleKommo(prospectData);
 *   };
 *
 *   return (
 *     <button onClick={sendDataToKommo}>Send to Kommo</button>
 *   );
 * };
 */
const useKommo = () => {
  /**
   * Checks if a lead already exists in Kommo by matching its email/phone
   * and development name with existing leads.
   *
   * @async
   * @function checkLeadExist
   * @param {Object} data - Prospect's information.
   * @returns {Promise<boolean>} True if the lead exists, false otherwise.
   */
  const checkLeadExist = async (data) => {
    const leadsResponse = await request.get(
      `/kommo/leads?development=${data.nomDesarrollo}`
    );
    const leads = Array.isArray(leadsResponse.data?._embedded?.leads)
      ? leadsResponse.data._embedded.leads
      : [];

    const existingLead = leads.find((lead) => {
      const emailField = lead.custom_fields_values?.find(
        (field) => field.field_name === "Correo"
      );
      const phoneField = lead.custom_fields_values?.find(
        (field) => field.field_name === "Teléfono"
      );
      const developmentField = lead.custom_fields_values?.find(
        (field) => field.field_name === "Desarrollo"
      );

      const leadEmail = emailField?.values?.[0]?.value || null;
      const leadPhone = phoneField?.values?.[0]?.value || null;
      const leadDevelopment = developmentField?.values?.[0]?.value || null;

      return (
        leadDevelopment === data.nomDesarrollo &&
        (leadEmail === data.emailclient || leadPhone === data.phoneclient)
      );
    });

    return Boolean(existingLead);
  };

  /**
   * Sends the prospect's information to Kommo to create a new lead.
   *
   * @async
   * @function sendLead
   * @param {Object} data - Prospect's information.
   * @returns {Promise<void>}
   */
  const sendLead = async (data) => {
    const kommoData = {
      leadId: data.pkclient,
      name: data.client,
      email: data.emailclient,
      phone: data.phoneclient,
      city: data.city,
      budget: 0,
      development: data.nomDesarrollo,
    };

    const kommoResponse = await request.post("/kommo/leads", kommoData);

    if (kommoResponse && kommoResponse.error) {
      toast.error("Error al enviar los datos a Kommo", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });

      return false;
    }

    if (kommoResponse && !kommoResponse.error) {
      toast.success("Prospecto enviado a Kommo", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      return true;
    }
  };

  /**
   * Main function that orchestrates the verification and sending of a prospect to Kommo.
   * 1. Checks if the prospect has a phone number or email (required).
   * 2. Calls `checkLeadExist` to see if the lead already exists in Kommo.
   * 3. If it does not exist, calls `sendLead` to create the new lead in Kommo.
   * 4. Handles any potential errors with notifications.
   *
   * @async
   * @function handleKommo
   * @param {Object} data - Prospect's information.
   * @returns {Promise<void>}
   */
  const handleKommo = async (data) => {
    try {
      if (!data.phoneclient) {
        toast.error("Es necesario que el prospecto tenga número telefónico.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      const leadExists = await checkLeadExist(data);

      if (!leadExists) {
        const response = await sendLead(data);
        return response;
      } else {
        console.log("El lead ya existe en Kommo.");
      }
    } catch (error) {
      toast.error(
        "Ocurrió un error al procesar el lead. Intentelo más tarde.",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }
      );
    }
  };

  return {handleKommo};
};

export default useKommo;
