import React, {useState} from "react";
import {Container, Card, Input, Button, Row, Col} from "reactstrap";
import {toast} from "react-toastify";
import Request from "../../api/httpClient";
import {Breadcrumbs} from "../../AbstractElements";
import DataTableComponent from "../../Components/Tables/DataTable/DataTableComponent";

const request = new Request();

export default function GlobalSearch() {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);

  const handleSearch = async () => {
    try {
      if (!query.trim()) return;
      setLoading(true);

      const response = await request.get(`/global-search?query=${query}`);

      if (response && !response.error) {
        setResult(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.message || "Hubo un error en la busqueda", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        "Hubo un error al procesar la busqueda. Intentelo más tarde",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }
      );
    }
  };

  const columns = [
    {name: "Fecha", selector: "date"},
    {name: "Nombre", selector: "name"},
    {name: "Correo", selector: "email"},
    {name: "Telefono", selector: "phone"},
    {name: "Desarrollo", selector: "development"},
    {name: "Tipo", selector: "type"},
    {name: "Asesor", selector: "advisor"},
  ];

  return (
    <>
      <Breadcrumbs
        mainTitle="Buscador Global"
        parent="Gestión"
        title="Buscador Global"
      />
      <Container fluid={true}>
        <Card
          style={{
            padding: "20px",
          }}
        >
          <Row className="align-items-center">
            <Col md="10">
              <Input
                type="text"
                placeholder="Buscar por nombre, correo o teléfono..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="form-control"
              />
            </Col>
            <Col md="2" className="text-end">
              <Button
                color="info"
                onClick={handleSearch}
                disabled={loading}
                style={{
                  minWidth: 120,
                  marginRight: 10,
                }}
              >
                <i className="fa fa-search" /> Buscar
              </Button>
            </Col>
          </Row>
        </Card>
        <Card
          style={{
            minHeight: `calc(80vh - 145px)`,
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          {result.length > 0 && (
            <Row>
              <Col>
                <DataTableComponent
                  tableColumns={columns}
                  tableRows={result}
                  loadingData={loading}
                  pagination={true}
                  scrollHeight={"calc(84vh - 230px)"}
                  minHeight={`calc(82vh - 230px)`}
                />
              </Col>
            </Row>
          )}
        </Card>
      </Container>
    </>
  );
}
