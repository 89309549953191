import {useState, useEffect} from "react";
import {toast} from "react-toastify";
import Request from "../../api/httpClient";

const request = new Request();

const useTracing = (prospect) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tracings, setTracings] = useState([]);
  const [tracing, setTracing] = useState(null);
  const [leftTab, setLeftTab] = useState("1");
  const [showModal, setShowModal] = useState({
    create: false,
    edit: false,
  });

  const handleModal = (type) => {
    setShowModal((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const fetchTracings = async () => {
    setIsLoading(true); // Activar el estado de carga
    setTracings([]); // Limpiar los datos anteriores

    const data = {
      id_prospecto: prospect.id,
    };
    const response = await request.post(
      "/prospectos/prospecto/seguimiento/get",
      data
    );

    if (response && !response.error) {
      if (response.length > 0) {
        setTracings(response); // Actualizar los datos
        setLeftTab(response[0].pkbinnacle);
        setTracing(response[0]);
      } else {
        setTracings([]); // No hay datos
      }
    } else {
      console.error(response.message); // Manejar errores
    }

    setIsLoading(false); // Desactivar el estado de carga
  };

  const reload = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
    });
    fetchTracings();
  };

  useEffect(() => {
    fetchTracings();
  }, [prospect]);

  return {
    handleModal,
    isLoading,
    tracings,
    tracing,
    leftTab,
    setLeftTab,
    setTracing,
    showModal,
    reload,
  };
};

export default useTracing;
