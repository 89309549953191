import React, {useState, useEffect} from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import EditProspect from "./edit-form";
import Request from "../../api/httpClient";
import ProspectZapierForm from "../Prospectos/components/Forms/ProspectZapierForm";
import Tracing from "../tracing/Tracing";

const request = new Request();

const ProspectTabs = ({id, onClose, reload, sources, stages}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [prospect, setProspect] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleTabClick = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const fetchProspect = async () => {
    setProspect([]);
    setIsLoading(true);

    const response = await request.get(`/prospects/${id}`);

    if (response && !response.error) {
      if (response && !response.empty) {
        setProspect(response);
      } else {
        setProspect({});
        setIsLoading(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProspect();
  }, []);

  return (
    <div className="m-b-20 m-l-10 m-r-10" style={{marginTop: "-20px"}}>
      <Nav tabs justified pills>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => {
              handleTabClick("1");
            }}
          >
            Editar prospecto
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => {
              handleTabClick("2");
            }}
          >
            Zapier
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => {
              handleTabClick("3");
            }}
          >
            Seguimiento
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <>
            {isLoading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <EditProspect
                prospect={prospect}
                onClose={onClose}
                reload={reload}
                sources={sources}
                stages={stages}
                fetchProspect={fetchProspect}
              />
            )}
          </>
        </TabPane>
        <TabPane tabId="2">
          <ProspectZapierForm prospect={prospect} />
        </TabPane>
        <TabPane tabId="3">
          <Tracing prospect={prospect} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default ProspectTabs;
