import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {Form, FormGroup, Label, Row} from "reactstrap";
import {Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import Select from "react-select";
import {ModalFooter} from "reactstrap";
import {toast} from "react-toastify";

const request = new Request();

export default function CloneProspect({
  onClose,
  reload,
  developments,
  prospect,
  stages,
}) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [selectedDevelopment, setSelectedDevelopment] = useState({});
  const [stage, setStage] = useState(stages[0]);
  const [apartments, setApartments] = useState([]);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [loadingApartments, setLoadingApartments] = useState(false);

  useEffect(() => {
    if (selectedDevelopment) {
      getDepartamentos();
    }
  }, [selectedDevelopment]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitDisabled(true);

    let data = {
      id: prospect.pkclient,
      developmentId: selectedDevelopment.id,
      interest: selectedApartment ? selectedApartment.label : null,
      stageId: stage.id,
    };

    const response = await request.post("/prospects/clone", data);

    if (response && !response.error) {
      onClose();
      setIsSubmitDisabled(false);
      reload(
        true,
        "Prospecto añadido al desarrollo " + selectedDevelopment.label,
        selectedDevelopment.value
      );
    } else {
      setIsSubmitDisabled(false);
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const getDepartamentos = async () => {
    setApartments([]);
    setLoadingApartments(true);
    const data = {
      id_desarrollo: selectedDevelopment.id,
    };
    const response = await request.post("/prospectos/get/departamentos", data);
    if (response && !response.error) {
      if (response && !response.empty) {
        setApartments(
          response.map((dpto) => {
            return {
              id: dpto.pkdepto,
              value: dpto.depto,
              label: dpto.depto,
            };
          })
        );
      } else {
        setApartments([]);
        setLoadingApartments(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingApartments(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <FormGroup className="row">
          <Col md="12">
            <Label className="col-form-label text-start">Desarrollo:</Label>
            <Select
              placeholder={"Seleccione..."}
              options={developments.filter(
                (development) => development.id !== prospect.desarrolloID
              )}
              onChange={(development) => setSelectedDevelopment(development)}
              required
            />
          </Col>
          <Col md="12">
            <Label className="col-form-label text-start">Interesados en:</Label>
            <Select
              placeholder={"Seleccione..."}
              options={apartments}
              onChange={(apartment) => setSelectedApartment(apartment)}
              isLoading={loadingApartments}
              isClearable
            />
          </Col>
          <Col sm="12">
            <Label className="col-form-label text-start">Etapa:</Label>
            <Select
              placeholder={"Seleccione..."}
              value={stage}
              options={stages}
              onChange={(stage) => setStage(stage)}
              required
            />
          </Col>
        </FormGroup>
      </Row>
      <ModalFooter>
        <Btn attrBtn={{color: "secondary", onClick: onClose}}>Cancelar</Btn>
        <Btn
          attrBtn={{
            type: "submit",
            color: "primary",
            disabled: isSubmitDisabled,
          }}
        >
          {isSubmitDisabled ? "Guardando..." : "Guardar"}
        </Btn>
      </ModalFooter>
    </Form>
  );
}
