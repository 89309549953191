import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {es} from "date-fns/locale";
import {Container, Row, Col, Card, Label} from "reactstrap";
import {Breadcrumbs} from "../../AbstractElements";
import DataTableComponent from "../../Components/Tables/DataTable/DataTableComponent";
import useReports from "../../Hooks/reports/useReposts";
import ToolbarButtons from "../../Components/shared/Buttons/ToolbarButtons";

export default function Reports() {
  const {
    columns,
    isLoading,
    from,
    setFrom,
    to,
    setTo,
    fetchProspects,
    handleReport,
    developments,
    setSelectedDevelopment,
    filteredData,
    ratings,
    setSelectedRating,
    userRole,
    allDevelopments,
  } = useReports();

  return (
    <>
      <Breadcrumbs parent="Marketing" title="Reportes" mainTitle="Reportes" />
      <Container fluid={true}>
        <Card style={{minHeight: `calc(90vh - 140px)`, padding: "20px"}}>
          <Row className="row-gap-2">
            {developments.length > 1 && (
              <Col lg="3" md="12">
                <Select
                  placeholder="Seleccionar desarrollo"
                  options={developments}
                  onChange={(development) =>
                    setSelectedDevelopment(development)
                  }
                  isClearable
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 3,
                    }),
                  }}
                />
              </Col>
            )}
            {userRole === "A" && (
              <Col lg="3" md="12">
                <Select
                  placeholder="Seleccionar desarrollo"
                  options={allDevelopments}
                  onChange={(development) =>
                    setSelectedDevelopment(development)
                  }
                  isClearable
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 3,
                    }),
                  }}
                />
              </Col>
            )}
            <Col lg="3" md="12">
              <Select
                placeholder="Seleccionar calificacion"
                options={ratings}
                onChange={(rating) =>
                  setSelectedRating(rating ? rating.value : 0)
                }
                isClearable
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 3,
                  }),
                }}
              />
            </Col>
            <Col lg="3" md="12">
              <Row className="align-items-center">
                <Col md="3">
                  <Label>Desde:</Label>
                </Col>
                <Col md="9">
                  <ReactDatePicker
                    selected={from}
                    onChange={(date) => {
                      setFrom(date);
                    }}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    locale={es}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg="3" md="12">
              <Row className="align-items-center">
                <Col md="3">
                  <Label>Hasta:</Label>
                </Col>
                <Col md="9">
                  <ReactDatePicker
                    className="form-control digits"
                    selected={to}
                    dateFormat="dd/MM/yyyy"
                    locale={es}
                    onChange={(date) => {
                      setTo(date);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="flex justify-content-end m-t-10">
            <ToolbarButtons
              filename="Reportes"
              onExcelClick={handleReport}
              onFilterClick={fetchProspects}
            />
          </div>
          <div className="m-t-10 m-b-8">
            <DataTableComponent
              tableColumns={columns}
              tableRows={filteredData}
              loadingData={isLoading}
              pagination={true}
              scrollHeight={"calc(84vh - 230px)"}
              minHeight={`calc(82vh - 230px)`}
            />
          </div>
        </Card>
      </Container>
    </>
  );
}
