import React from "react";
import DataTable from "react-data-table-component";
import {Image, P} from "../../../AbstractElements";
import search from "../../../assets/images/search-not-found.png";

const CustomLoader = () => (
  <div className="loader-box">
    <div className="loader-15" />
  </div>
);

const DataTableComponent = (props) => {
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const CustomNoDataComponent = ({width}) => (
    <div className="search-not-found text-center">
      <div>
        <Image
          attrImage={{
            src: `${search}`,
            alt: "",
            className: "second-search",
            style: {width: width},
          }}
        />
        <P attrPara={{className: "mb-2"}}>{"No se encontraron datos"}</P>
      </div>
    </div>
  );

  return (
    <DataTable
      title={props.title}
      data={props.tableRows}
      columns={props.tableColumns}
      striped={true}
      center={true}
      fixedHeader
      fixedHeaderScrollHeight={props.scrollHeight || "500px"}
      pagination={props.pagination}
      paginationRowsPerPageOptions={[20, 30, 40, 50]}
      paginationComponentOptions={paginationComponentOptions}
      highlightOnHover={true}
      pointerOnHover={true}
      expandOnRowClicked={true}
      progressPending={props.loadingData}
      progressComponent={<CustomLoader />}
      noDataComponent={
        <CustomNoDataComponent width={props.imageWidth || "80%"} />
      }
      conditionalRowStyles={props.conditionalRowStyles}
      expandableRows={props.expandableRows}
      expandableRowsComponent={props.expandableRowsComponent}
      customStyles={{
        tableWrapper: {
          style: {
            minHeight: props.minHeight,
          },
        },
      }}
    />
  );
};
export default DataTableComponent;
