import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {Form, Input, Label, Row, ModalFooter} from "reactstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import Select from "react-select";
import {toast} from "react-toastify";

const request = new Request();

export default function EditBroker({
  broker,
  onClose,
  reload,
  sources,
  advisors,
}) {
  const userId = localStorage.getItem("user_id") || 0;
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
  });

  useEffect(() => {
    if (broker) {
      setFormData({
        name: broker.name || "",
        email: broker.email || "",
        phone: broker.phone || "",
        city: broker.city || "",
      });
      setSelectedSource(
        sources.find((source) => source.value === broker.origin)
      );
      setSelectedAdvisor(
        advisors.find((advisor) => advisor.id === broker.advisorId)
      );
    }
  }, [broker]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsSubmit(true);

      const {name, email, phone, city} = formData;

      if (!email) {
        toast.warning("El correo es requerido", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      if (!phone) {
        toast.warning("El teléfono es requerido", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      let normalizedPhone = phone;
      if (phone.length === 12 && phone.startsWith("52")) {
        normalizedPhone = phone.slice(2);
      } else if (phone.length === 13 && phone.startsWith("521")) {
        normalizedPhone = phone.slice(3);
      } else if (phone.length === 14 && phone.startsWith("5201")) {
        normalizedPhone = phone.slice(4);
      }

      if (normalizedPhone.startsWith("0")) {
        toast.warning("Ningún número de teléfono puede iniciar con ceros", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      if (normalizedPhone.length < 10) {
        toast.warning("El número de teléfono debe tener al menos 10 dígitos", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      const trimmedName = name.trim();
      const cleanedEmail = email.replace(/\s+/g, "");
      const editionDate = new Date()
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      let data = {
        name: trimmedName,
        email: cleanedEmail,
        phone: normalizedPhone,
        city: city,
        origin: selectedSource.value,
        advisorId: selectedAdvisor ? selectedAdvisor.id : 0,
        editDate: editionDate,
        editorId: userId,
      };

      const response = await request.put(`/brokers/${broker.id}`, data);

      if (response && !response.error) {
        setIsSubmit(false);
        onClose();
        reload();
        toast.success(response.message || "Broker editado correctamente", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      } else {
        setIsSubmit(false);
        toast.error(response.message || "Hubo un error al editar el broker", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      setIsSubmit(false);
      toast.error("Hubo un error al procesar la solicitud", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: field === "phone" ? value.replace(/[^0-9]/g, "") : value,
    }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="p-10">
        <Col xl="6" sm="12">
          <Label className="col-form-label text-start">Nombre:</Label>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            required
          />
        </Col>
        <Col xl="6" sm="12">
          <Label className="col-form-label text-start">Email:</Label>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            required
          />
        </Col>
        <Col xl="6" sm="12">
          <Label className="col-form-label text-start">Teléfono:</Label>
          <Input
            type="tel"
            name="phone"
            minLength={10}
            maxLength={15}
            value={formData.phone}
            onChange={(e) => handleInputChange("phone", e.target.value)}
            required
          />
        </Col>
        <Col xl="6" sm="12">
          <Label className="col-form-label text-start">Ciudad:</Label>
          <Input
            type="text"
            name="city"
            value={formData.city}
            onChange={(e) => handleInputChange("city", e.target.value)}
          />
        </Col>

        <Col xl="6" sm="12" style={{zIndex: 997}}>
          <Label className="col-form-label text-start">
            Fuente de contacto:
          </Label>
          <Select
            placeholder="Seleccione..."
            value={selectedSource}
            options={sources}
            onChange={(source) => setSelectedSource(source)}
            required
          />
        </Col>
        <Col xl="6" sm="12" style={{zIndex: 996}}>
          <Label className="col-form-label text-start">Asesor:</Label>
          <Select
            placeholder="Seleccione..."
            value={selectedAdvisor}
            options={advisors}
            onChange={(advisor) => setSelectedAdvisor(advisor)}
          />
        </Col>
      </Row>
      <ModalFooter>
        <Btn attrBtn={{color: "secondary", onClick: onClose}}>Cancelar</Btn>
        <Btn
          attrBtn={{
            type: "submit",
            color: "primary",
            disabled: isSubmit,
          }}
        >
          {isSubmit ? "Guardando..." : "Guardar"}
        </Btn>
      </ModalFooter>
    </Form>
  );
}
