import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {Form, Input, Label, Row, ModalFooter} from "reactstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import Select from "react-select";
import {toast} from "react-toastify";
import {ratings} from "../../Data/options";

const request = new Request();

export default function CreateProspect({
  onClose,
  reload,
  developments,
  sources,
  stages,
}) {
  const userId = localStorage.getItem("user_id") || 0;
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedDevelopment, setSelectedDevelopment] = useState({});
  const [apartments, setApartments] = useState([]);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [loadingApartments, setLoadingApartments] = useState(false);
  const [selectedStage, setSelectedStage] = useState({});
  const [selectedSource, setSelectedSource] = useState({});
  const [selectedRating, setSelectedRating] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    comments: "",
    budget: "",
  });

  useEffect(() => {
    if (selectedDevelopment) {
      getDepartamentos();
    }
  }, [selectedDevelopment]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsSubmit(true);

      const {name, email, phone, city, comments, budget} = formData;

      if (!email) {
        toast.warning("El correo es requerido", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      if (!phone) {
        toast.warning("El teléfono es requerido", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      let normalizedPhone = phone;
      if (phone.length === 12 && phone.startsWith("52")) {
        normalizedPhone = phone.slice(2);
      } else if (phone.length === 13 && phone.startsWith("521")) {
        normalizedPhone = phone.slice(3);
      } else if (phone.length === 14 && phone.startsWith("5201")) {
        normalizedPhone = phone.slice(4);
      }

      if (normalizedPhone.startsWith("0")) {
        toast.warning("Ningún número de teléfono puede iniciar con ceros", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      if (normalizedPhone.length < 10) {
        toast.warning("El número de teléfono debe tener al menos 10 dígitos", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setIsSubmit(false);
        return;
      }

      const trimmedName = name.trim();
      const cleanedEmail = email.replace(/\s+/g, "");

      let data = {
        name: trimmedName,
        email: cleanedEmail,
        phone: normalizedPhone,
        city: city,
        budget: budget,
        comments: comments,
        interest: selectedApartment ? selectedApartment.label : null,
        origin: selectedSource.value,
        qualification: selectedRating.value,
        developmentId: selectedDevelopment.id,
        stageId: selectedStage.id,
        registerId: userId,
      };

      const response = await request.post("/prospects", data);

      if (response && !response.error) {
        setIsSubmit(false);
        onClose();
        reload();
        toast.success(response.message || "Prospecto creado correctamente", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      } else {
        setIsSubmit(false);
        toast.error(response.message || "Hubo un error al crear el prospecto", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      setIsSubmit(false);
      toast.error("Hubo un error al procesar la solicitud", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getDepartamentos = async () => {
    setApartments([]);
    setLoadingApartments(true);
    const data = {
      id_desarrollo: selectedDevelopment.id,
    };
    const response = await request.post("/prospectos/get/departamentos", data);
    if (response && !response.error) {
      if (response && !response.empty) {
        setApartments(
          response.map((dpto) => {
            return {
              id: dpto.pkdepto,
              value: dpto.depto,
              label: dpto.depto,
            };
          })
        );
      } else {
        setApartments([]);
        setLoadingApartments(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingApartments(false);
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;

    if (name === "phone") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="p-10">
        <Col xl="4" sm="9">
          <Label className="col-form-label text-start">Nombre:</Label>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Col>
        <Col xl="4" sm="9">
          <Label className="col-form-label text-start">Email:</Label>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </Col>
        <Col xl="4" sm="9">
          <Label className="col-form-label text-start">Teléfono:</Label>
          <Input
            type="tel"
            name="phone"
            minLength={10}
            maxLength={15}
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </Col>
        <Col xl="4" sm="9">
          <Label className="col-form-label text-start">Ciudad:</Label>
          <Input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
          />
        </Col>
        <Col xl="4" sm="9">
          <Label className="col-form-label text-start">Desarrollo:</Label>
          <Select
            placeholder="Seleccione..."
            options={developments}
            onChange={(development) => setSelectedDevelopment(development)}
            isClearable
            required
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 3,
              }),
            }}
          />
        </Col>
        <Col xl="4" sm="9">
          <Label className="col-form-label text-start">Interesados en:</Label>
          <Select
            placeholder={"Seleccione..."}
            options={apartments}
            onChange={(apartment) => setSelectedApartment(apartment)}
            value={selectedApartment}
            isLoading={loadingApartments}
            isClearable
          />
        </Col>
        <Col xl="4" sm="9">
          <Label className="col-form-label text-start">Presupuesto:</Label>
          <Input
            type="text"
            name="budget"
            value={formData.budget}
            onChange={handleInputChange}
          />
        </Col>
        <Col xl="4" sm="9">
          <Label className="col-form-label text-start">Calificación:</Label>
          <Select
            placeholder="Seleccione..."
            options={ratings}
            onChange={(rating) => setSelectedRating(rating)}
            isClearable
            required
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 3,
              }),
            }}
          />
        </Col>
        <Col xl="4" sm="9">
          <Label className="col-form-label text-start">
            Fuente de contacto:
          </Label>
          <Select
            placeholder="Seleccione..."
            options={sources}
            onChange={(source) => setSelectedSource(source)}
            isClearable
            required
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 3,
              }),
            }}
          />
        </Col>
        <Col xl="4" sm="9">
          <Label className="col-form-label text-start">Etapa:</Label>
          <Select
            placeholder="Seleccione..."
            options={stages}
            onChange={(stage) => setSelectedStage(stage)}
            isClearable
            required
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 3,
              }),
            }}
          />
        </Col>
        <Col xl="12">
          <Label className="col-form-label text-start">Comentarios:</Label>
          <Input
            type="textarea"
            name="comments"
            value={formData.comments}
            onChange={handleInputChange}
            rows="2"
          />
        </Col>
      </Row>
      <ModalFooter>
        <Btn attrBtn={{color: "secondary", onClick: onClose}}>Cancelar</Btn>
        <Btn
          attrBtn={{
            type: "submit",
            color: "primary",
            disabled: isSubmit,
          }}
        >
          {isSubmit ? "Guardando..." : "Guardar"}
        </Btn>
      </ModalFooter>
    </Form>
  );
}
