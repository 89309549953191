import React from "react";
import moment from "moment";
import ProspectoMenu from "../../Components/Prospectos/ProspectoMenu";
import EditButton from "../../Components/shared/Buttons/EditButton";

export const prospectsColumns = (
  handleEdit,
  handleEliminarProspecto,
  handleCambiarStatusProspecto,
  handleClonarProspecto,
  handleSendKommo
) => [
  {
    name: "Registro",
    selector: (row) => moment(row.registerclient),
    cell: (row) => `${moment(row.registerclient).format("DD-MM-YYYY")}`,
    sortable: true,
  },
  {
    name: "Nombre",
    selector: (row) => `${row.client}`,
    sortable: true,
  },
  {
    name: "Teléfono",
    selector: (row) => `${row.phoneclient}`,
    sortable: true,
  },
  {
    name: "Desarrollo",
    selector: (row) => `${row?.nomDesarrollo || ""}`,
    sortable: true,
  },
  {
    name: <div style={{width: "100%", textAlign: "center"}}>Etapa</div>,
    selector: (row) => `${row.funnelName}`,
    sortable: true,
    center: false,
    cell: (row) => {
      let badgeClass = "badge-light-light";
      let statusText = row.funnelName;

      return (
        <div style={{width: "100%", textAlign: "center"}}>
          <span className={`badge ${badgeClass}`}>{statusText}</span>
        </div>
      );
    },
  },
  {
    name: "Asesor",
    selector: (row) => `${row.username || "No asignado"}`,
    sortable: true,
  },
  {
    minWidth: "80px",
    button: true,
    ignoreRowClick: true,
    allowOverflow: true,
    center: true,
    selector: (row) => "",
    cell: (row) => <EditButton row={row} onEditRow={handleEdit} />,
  },
  {
    minWidth: "50px",
    button: true,
    ignoreRowClick: true,
    allowOverflow: true,
    center: false,
    selector: (row) => "",
    cell: (row) => (
      <ProspectoMenu
        size="small"
        row={row}
        onDeleteRow={handleEliminarProspecto}
        onUpdateStatusRow={handleCambiarStatusProspecto}
        onUpdateDesarrolloRow={handleClonarProspecto}
        onKommoModal={handleSendKommo}
      />
    ),
  },
];
