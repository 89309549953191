import React, {Fragment} from "react";
import {Container, Row, Col, Card, CardBody} from "reactstrap";
import Chart from "react-apexcharts";
import {User} from "react-feather";
import CountUp from "react-countup";
import {Breadcrumbs, H5} from "../../AbstractElements";
import ProspectoInfoWidget from "../../Components/Dashboard/Default/ProspectoInfoWidget";
import ProspectoTotalWidget from "../../Components/Dashboard/Default/ProspectoTotalWidget";
import HeaderCard from "../../Components/Common/Component/HeaderCard";
import useIndex from "../../Hooks/dashboard/useIndex";

export default function Home() {
  const {
    totalProspectos,
    fuenteProspecto,
    totalUsuarios,
    role,
    desarrollos,
    onDesarrolloSelected,
  } = useIndex();

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Inicio" parent="Gestión" title="Inicio" />
      <Container fluid={true}>
        <Col xxl="12" md="12" className="box-col-8 grid-ed-12">
          <Row className="row">
            <Col xxl="4" md="6" className="appointment-sec box-col-6">
              <ProspectoInfoWidget
                desarrollos={desarrollos}
                onOptionSelected={onDesarrolloSelected}
                totalProspectos={totalProspectos}
              />
            </Col>
            <Col xxl="8" md="6" className="box-col-6">
              <Row>
                {totalProspectos.map((data, i) => (
                  <Col xs="6" md="3" key={i}>
                    <ProspectoTotalWidget data={data} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      </Container>
      <Container fluid={true}>
        <Col xxl="12" md="12" className="box-col-8 grid-ed-12">
          {role === "A" ? (
            <Row className="row">
              <Col sm="12" xl="6">
                <Card>
                  <HeaderCard title="Fuentes" />
                  <CardBody className="apex-chart p-t-0">
                    {Object.keys(fuenteProspecto).length !== 0 ? (
                      <div id="piechart">
                        <Chart
                          options={fuenteProspecto.options}
                          series={fuenteProspecto.series}
                          type="pie"
                          width={400}
                        />
                      </div>
                    ) : (
                      <div className="loader-box " style={{height: "5px"}}>
                        <div className="loader-15" />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardBody>
                    {totalUsuarios.length > 0 ? (
                      <div
                        className="balance-profile"
                        style={{backgroundImage: "none"}}
                      >
                        <span className="f-light d-block text-center">
                          Total de Usuarios
                        </span>
                        <H5
                          attrH5={{
                            className: "mt-1 text-center m-b-30",
                          }}
                        >
                          <CountUp
                            duration={3}
                            separator=","
                            end={totalUsuarios.reduce(
                              (total, usuario) => total + usuario.cantidad,
                              0
                            )}
                          />
                        </H5>
                        <Row>
                          {totalUsuarios.map((item, i) => (
                            <Col xs="6" md="4" key={i}>
                              <div className={"balance-item success"}>
                                <div className="balance-icon-wrap">
                                  <div className="balance-icon">
                                    <User />
                                  </div>
                                </div>
                                <div>
                                  <span className="f-12 f-light">
                                    {item.nombre}
                                  </span>
                                  <H5>
                                    {" "}
                                    <CountUp
                                      duration={3}
                                      separator=","
                                      end={item.cantidad}
                                    />
                                  </H5>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ) : (
                      <div className="loader-box">
                        <div className="loader-15" />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row className="row">
              <Col sm="12" xl="12">
                <Card>
                  <HeaderCard title="Fuentes" />
                  <CardBody className="apex-chart p-t-0">
                    {Object.keys(fuenteProspecto).length !== 0 ? (
                      <div id="piechart">
                        <Chart
                          options={fuenteProspecto.options}
                          series={fuenteProspecto.series}
                          type="pie"
                          width={400}
                        />
                      </div>
                    ) : (
                      <div className="loader-box " style={{height: "5px"}}>
                        <div className="loader-15" />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Container>
    </Fragment>
  );
}
